//BOOTSTRAP OVERIDES
/**/
$darkRed:	      #A21212;
$darkYellow:            #F9C73F;
$gold:            #A1865B;
$grey:            #ECF2ED;
$darkGrey:  #7B7F86;


$primary:           #282029;
$secondary:         $darkYellow;
$grid-gutter-width:         24px;

$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 768px,
  lg: 960px,
  xl: 1200px,
  xxl: 1248px
);

$container-max-widths: (
  sm: 768px,
  md: 960px,
  lg: 100%,
  xl: 1200px,
  xxl: 1248px
);

$theme-colors: (
  "grey": $grey,
  "darkYellow": $darkYellow
);


$titleFont: 'Catamaran', sans-serif;
$bodyFont:  'Montserrat', sans-serif;

$font-family-base:    $bodyFont;     
$enable-shadows:  false;

//Buttons
$btn-padding-y: 17px;
$btn-padding-x: 34px;
$btn-font-weight: 500;
$btn-line-height: 11px;
$btn-border-radius: 25px;
$btn-font-size: 11px;
$btn-font-family: $bodyFont;
$btn-focus-box-shadow: none !important;


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

//shared css
body{
    background: $grey;
    color: $primary;
}

h1, h2, h3, h4{
    font-family: $titleFont;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 2rem;
}

h1{
  font-size: 2rem;
}

.card-title{
  font-family: 'Catamaran', sans-serif;;
  text-transform: uppercase;
  font-weight: 800;
}