.headerImage{
    width: 100%;
    display: block;
    padding-bottom: 45%;
    background-position: center;
    position: relative;
    background-image: url('../assets/header/img3.jpg');
    background-size: cover;
    background-attachment: fixed;
}

.headerImage::after{
   position: absolute;
   top:0;
   left:0;
   width: 100%;
   height: 100%;
   content: ' ';
   background:#282029 ;
   opacity: 0.25;
}