.page {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  transition: opacity 500ms ease-in-out;
}

.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
}

.page-exit {
  opacity: 0;
}

.page--prev.page-enter {
  transform: translate(100%, 0);
}

.page--prev.page-enter-active {
  transform: translate(0, 0);
}

.page--prev.page-exit {
  transform: translate(100%, 0);
}

.section-spacing{
  padding-top: 110px;
  padding-bottom: 110px;
}

.section-spacing:first-child{
  margin-top: 110px;
}
.section-spacing + .section-spacing{
  padding-top: 0;
}

@media (min-width: 700px) {
  .section-spacing:first-child{
    margin-top: 170px;
  }
}
