.mainHeader{
    text-align: center;
    margin: 15px 0 0 0;
    position: absolute;
    top:0;
    left:0;
    z-index: 10;
    width: 100%;
    display: block;
    color: #ECF2ED;
}


.mainHeader::before{
    position: absolute;
    top: 50%;
    left:0;
    width: 100%;
    content: ' ';
    background: #F9C73F;
    height: 40px;
    transform: translateY(-50%);
}

.mainHeader__logo{
    max-width: 110px;
    position: relative;
    z-index: 20;

    
}

@media (min-width: 700px) {
    .mainHeader__logo{
        max-width: 160px;
    }
}

.nav{
    position: absolute;
    top: 38%;
    left: 50%;
    z-index: 5;
    transform: translate(-50%);

}

    .nav a{
        font-weight: 500;
        letter-spacing: 0.6px;
        font-weight: 500;
        font-size: 20px;
        font-family: 'Catamaran', sans-serif;
        text-transform: uppercase;
        font-weight: 800;
    }

.navLeft{
    margin-right: 80px;
    
}

.navRight{
    margin-left: 80px;
}

@media (min-width: 700px) {
    .nav{
        top: 40%;
    }

    .mainHeader__logo{
        max-width: 160px;
    }

    .navLeft{
        margin-right: 140px;
        
    }

    .navRight{
        margin-left: 140px;
    }

    .mainHeader::before{
        height: 60px;
    }
    
}