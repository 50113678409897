.mainFooter{
    padding: 120px 0 160px;
    background: #F9C73F url(../assets/footerPattern.png) repeat center;
    clear: both;
    text-align: center;
}

.mainFooter__links{
    list-style: none;
    margin:0 0 40px;
    padding:0;
}

.mainFooter__link{
    padding: 10px 20px 0 20px;
    line-height: 32px;
    display: inline;
   
}

.mainFooter__link a{
    color: #000000;
    letter-spacing: 0.6px;
    font-weight: 500;
}

.mainFooter__termsLinks{
    font-size: 12px;
    list-style: none;
    margin:0 0 20px;    
    padding:0;
}

    .mainFooter__termsLink{
        padding: 0 10px;
        display: inline;
    }

.mainFooter__icons{
    font-size: 10px;
}
